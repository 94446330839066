
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


@import "template/common/variables";

@import 'variables.scss';

@import "template/layout/layout";
@import "template/layout/top-navbar";



// NAVBAR TOP

$nav-top-bg:                    $color-primary-0;
$nav-top-bg-start:              $color-primary-0;
$nav-top-bg-end:                $color-primary-1;
$nav-header-bg:                 transparent;

$nav-top-item:                  #fff;
$nav-top-item-active:           darken($nav-top-bg, 20%);

.brand-logo > img {
    height: 34px;
}


.app-body {
    margin-top: 35px;
}


/* ========================================================================
   Component: top-navbar
 ========================================================================== */
 .topnavbar {
    background-color: $nav-top-bg;
    @include gradient-x($nav-top-bg-start, $nav-top-bg-end);

    @include media-breakpoint-up(lg) {
        .navbar-nav > .nav-item.show > .nav-link {
            &, &:hover, &:focus {
                box-shadow: 0 -3px 0 darken($nav-top-bg, 6%) inset;
            }
        }
    }

    .navbar-nav > .nav-item > .navbar-text {
        color: $nav-top-item;
    }

    .navbar-nav > .nav-item > .nav-link,
    .navbar-nav > .nav-item.show > .nav-link {
        color: $nav-top-item;
        &:hover, &:focus {
            color: $nav-top-item-active;
        }
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: $nav-top-bg;
    }
}

.navbar-brand {
    color: #fff;
}