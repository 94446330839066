body{
    background-color: #ffffff !important;
}
.header-navbar{
    background-color: #ffffff !important;    
}

/* fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  */
@font-face {
    font-family: 'overpass';
    src: url(../../assets/css/fonts/overpass-light.woff2) format('woff2'),url(../../assets/css/fonts/overpass-light.woff) format('woff');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'overpass';
    src: url(../../assets/css/fonts/overpass-regular.woff2) format('woff2'),url(../../assets/css/fonts/overpass-regular.woff) format('woff');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'overpass';
    src: url(../../assets/css/fonts/overpass-bold.woff2) format('woff2'),url(../../assets/css/fonts/overpass-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal
}

@font-face {
	font-family: "icon-font";
	src: url('../../assets/css/fonts/icon-font.eot');
	src: url('../../assets/css/fonts/icon-font.eot?#iefix') format('eot'), url('../../assets/css/fonts/icon-font.woff') format('woff'), url('../../assets/css/fonts/icon-font.ttf') format('truetype'), url('../../assets/css/fonts/icon-font.svg#icon-font') format('svg');
}
/* fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  fonts  */

.pr5{
    padding-right: 5px;
}
.pr10{
    padding-right: 10px;
}
/* logo logo logo logo logo logo logo logo logo */
.top-logo-bar {
    /*width: 100%; siva 9 feb*/
    background-color: #ffffff;
    padding: 20px;
}

.ortho-logo {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    background-size: contain;
    background-image: url(../../assets/css/Images/ortho-logo-black.png);
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    width: 290px;
    height: 26px;
    margin-bottom: 0
}

.right-0 {
    float: right !important;
    /* left: auto !important; */
    /* right: 0px !important; */
    /* position: absolute;
    top: 100%;
    left: 0px;
    z-index: 901; */
}
.red-color  {
    font-weight: normal !important;
    color: rgb(207, 47, 68) !important;
    font-size: 12px !important;
    /* font-weight: 600 !important; */
    line-height: 15px !important;
    /* padding-bottom: 10px !important; */
    text-decoration: none !important;
    display: block;
}


.hamburger {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    background-size: contain;
    /*background-image: url(../images/hamburger.png);*/
    background-repeat: no-repeat;
    content: '';
    display: inline-block !important;
    /*width: 30px;
    height: 30px;*/
    width: 50px;
    right: 20px;
    margin-bottom: 0;
    color: #333;
    font-size: 30px;
}



@media (min-width: 992px) {
    /* desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop */
    .app-body{
        min-height: 60vh;
    }

    .ortho-logo-container {
        width: 290px !important;
        height: 26px !important;
    }

    .menu-container{
        display: flex !important;
        justify-content: end;
        /* background-color: green; */
    }
    .resp-logo-container{

    }
    .resp-menu-container{
    
    }
    .resp-menu-container-adjust{
        maxHeight: '100px'
    }
    .app-body {
        padding-top: 0px !important;
    }

    .menu0{
        font-weight: bold;
        font-family: Overpass, sans-serif !important;
        font-size: 16px !important;
        color: rgb(33, 37, 41) !important;
        padding: 10px 12px !important;
        cursor: pointer;
    }
    .menu1{
        font-weight: bold;
        font-family: Overpass, sans-serif !important;
        font-size: 16px !important;
        color: rgb(33, 37, 41) !important;
        padding: 10px 12px !important;
        /* padding: 0.25rem 1.5rem; */
        cursor: pointer;
        width: auto !important;
        /* min-width: 110px; */
        /* display: contents !important; */
        /* width: max-content; */
        white-space: nowrap;
    }
    .menu1-header{
        font-weight: bold;
        font-family:   Overpass, sans-serif !important;
        font-size: 16px !important;
        color: rgb(111, 111, 112) !important;
        padding: 10px 12px !important;
        /* padding: 0.25rem 1.5rem !important;; */
        /* cursor: pointer; */
        display: contents;
        width: max-content;
    }
    .menu1:hover {
        background-color: rgb(232, 232, 232);
        background-image: linear-gradient(rgb(245, 245, 245) 0px, rgb(232, 232, 232) 100%);
        background-repeat: repeat-x;
    }
    .menu_block, .menu_block_sub  {
        -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
        border-radius: .25rem;
        border: 0px solid #ffffff !important;;
        /*overflow-y: auto; /*siva*/
        /*max-height: 600px;*/ /*siva*/
        padding: 20px 10px !important;;
        list-style: none;
        /*width: 350px !important;*/
        text-align: left;
        background-color: #ffffff;
    }
    .user-menu{
        display: block;
    }
    .user-logout{
        display: none;
    }
    
    .mobile-user-footer {
        display: none !important;
    }
    /* desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop desktop */
}


@media (max-width: 991.98px) {
    /* ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad */
    .app-body{
        min-height: 80vh;
    }

    .ortho-logo-container {
        width: 190px !important;
        height: 26px !important;
    }

    .menu-container{
        
    }
    .resp-logo-container{
        position: fixed !important;
        z-index: 99999;
        /* display: block !important; */
        width: 100% !important;
        /* background-color: blueviolet !important; */
        background-color: #ffffff !important;
        height: 86px;
        /* margin-bottom: 100px; */
        top: 0px;
        -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
    }
    .resp-menu-container{
        /* background-color: #ffffff; */
        /* height: 100vh !important; */
        height: 86vh !important;
        z-index: 9999;
        /* width: 100vh !important; */
        width: 100% !important;
        left: 0px !important;
        padding: 15px !important;
        position: fixed;
        top: 85px;
        background: linear-gradient(90deg, rgb(64, 64, 64) 0px, rgb(0, 0, 0) 100%) 0% 0% / cover;
        transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    }
    .resp-menu-container-adjust{
        height: 100vh;
    }
    .app-body {
        padding-top: 100px !important;
        margin-top: 0px !important;
    }

    .menu0{
        font-weight: bold;
        font-family: Overpass, sans-serif !important;
        font-size: 16px !important;
        color: #ffffff !important;
        padding: 10px 12px !important;
        cursor: pointer;
    }
    .menu1{
        font-weight: bold;
        font-family: Overpass, sans-serif !important;
        font-size: 16px !important;
        color: #ffffff !important;
        padding: 10px 12px !important; 
        cursor: pointer;
        width: auto !important; 
        white-space: nowrap;
    }
    .menu1-header{
        font-weight: 500;
        font-family:   Overpass, sans-serif !important;
        font-size: 14px !important;
        color: #ffffff !important;
        padding: 10px 12px !important; 
        display: contents;
        width: max-content;
    }
    .menu1:hover {
        background-color: rgb(232, 232, 232);
        background-image: linear-gradient(rgb(245, 245, 245) 0px, rgb(232, 232, 232) 100%);
        background-repeat: repeat-x;
    }
    .menu_block, .menu_block_sub  { 
        width: 96vw; /* for iphone 93vw*/
        border-radius: .25rem;
        border: 0px solid #ffffff !important; 
        padding: 20px 10px !important;;
        list-style: none; 
        text-align: left;
        background-color: #404040 !important; 
    }
    .user-menu{
        display: none;
    }
    .user-logout{
        display: block;
    }

    /* user bottom panel for mobile screens */
    .mobile-user-footer {
        left:0px;
        display: block;
        bottom: 0px !important;
        position: fixed !important;
        width: 100% !important;
        /* z-index: 1000; */
        z-index: 999999!important;
    }
    .top-panel__footer {
        background-image: -webkit-gradient(linear,left top,right top,from(#CF2F44),to(#8F191C));
        background-image: -o-linear-gradient(left,#CF2F44,#8F191C);
        background-image: linear-gradient(to right,#CF2F44,#8F191C);
        height: 88px;
        padding: 10px;
        z-Index: 1000;
    }
    .userMobileContent .user-name {
        color: #ffffff !important;
        font-size: 16px !important;
        font-size: 1.6rem;
        font-family: 'Overpass', sans-serif;
        line-height: 0px;
        padding-top: 23px;
    }
    
        .userMobileContent .user-name span {
            color: #ffffff !important;
        }
    
    .userMobileContent .user-email a {
        color: #ffffff !important;
        font-size: 16px !important;
        font-size: 1.6rem;
        font-family: 'Overpass', sans-serif;
        text-decoration: none !important;
        font-weight: 300 !important;
        line-height: 40px;
    }
    /* user bottom panel for mobile screens */

    /* ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad ipad */
}

@media (max-width: 575.98px) {
    /* iphone iphone iphone iphone iphone iphone iphone iphone iphone iphone    */
    .app-body{
        min-height: 60vh;
    }

    .ortho-logo-container {
        width: 190px !important;
        height: 26px !important;
    }

    .menu-container{
        
    }
    .resp-logo-container{
        position: fixed !important;
        z-index: 99999;
        /* display: block !important; */
        width: 100% !important;
        /* background-color: blueviolet !important; */
        background-color: #ffffff !important;
        height: 86px;
        /* margin-bottom: 100px; */
        top: 0px;
        -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
    }
    .resp-menu-container{
        /* background-color: #ffffff; */
        /* height: 100vh !important; */
        height: 86vh !important;
        z-index: 9999;
        /* width: 100vh !important; */
        width: 100% !important;
        left: 0px !important;
        padding: 15px !important;
        position: fixed;
        top: 85px;
        background: linear-gradient(90deg, rgb(64, 64, 64) 0px, rgb(0, 0, 0) 100%) 0% 0% / cover;
        transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
        overflow-x: hidden;
    }
    .resp-menu-container-adjust{
        height: 100vh;
    }
    .app-body {
        padding-top: 100px !important;
        margin-top: 0px !important;
    }

    .menu0{
        font-weight: bold;
        font-family: Overpass, sans-serif !important;
        font-size: 16px !important;
        color: #ffffff !important;
        padding: 10px 12px !important;
        cursor: pointer;
    }
    .menu1{
        font-weight: bold;
        font-family: Overpass, sans-serif !important;
        font-size: 16px !important;
        color: #ffffff !important;
        padding: 10px 12px !important; 
        cursor: pointer;
        width: auto !important; 
        white-space: nowrap;
    }
    .menu1-header{
        font-weight: 500;
        font-family:   Overpass, sans-serif !important;
        font-size: 14px !important;
        color: #ffffff !important;
        padding: 10px 12px !important; 
        display: contents;
        width: max-content;
    }
    .menu1:hover {
        background-color: rgb(232, 232, 232);
        background-image: linear-gradient(rgb(245, 245, 245) 0px, rgb(232, 232, 232) 100%);
        background-repeat: repeat-x;
    }
    .menu_block, .menu_block_sub  { 
        width: 92vw; /* for iphone 93vw*/
        border-radius: .25rem;
        border: 0px solid #ffffff !important; 
        padding: 20px 10px !important;;
        list-style: none; 
        text-align: left;
        background-color: #404040 !important; 
    }
    .user-menu{
        display: none;
    }
    .user-logout{
        display: block;
    }

    /* user bottom panel for mobile screens */
    .mobile-user-footer {
        left:0px;
        display: block;
        bottom: 0px !important;
        position: fixed !important;
        width: 100% !important;
        /* z-index: 1000; */
        z-index: 999999!important;
    }
    .top-panel__footer {
        background-image: -webkit-gradient(linear,left top,right top,from(#CF2F44),to(#8F191C));
        background-image: -o-linear-gradient(left,#CF2F44,#8F191C);
        background-image: linear-gradient(to right,#CF2F44,#8F191C);
        height: 88px;
        padding: 10px;
        z-Index: 1000;
    }
    .userMobileContent .user-name {
        color: #ffffff !important;
        font-size: 16px !important;
        font-size: 1.6rem;
        font-family: 'Overpass', sans-serif;
        line-height: 0px;
        padding-top: 23px;
    }
    
        .userMobileContent .user-name span {
            color: #ffffff !important;
        }
    
    .userMobileContent .user-email a {
        color: #ffffff !important;
        font-size: 16px !important;
        font-size: 1.6rem;
        font-family: 'Overpass', sans-serif;
        text-decoration: none !important;
        font-weight: 300 !important;
        line-height: 40px;
    }
    /* user bottom panel for mobile screens */

    /* iphone iphone iphone iphone iphone iphone iphone iphone iphone iphone    */
}