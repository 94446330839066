$ortho-red: #a41122;

$color-primary-0: #A41122;	// Main Primary color */
$color-primary-1: #DC5061;
$color-primary-2: #CB2539;
$color-primary-3: #83000F;
$color-primary-4: #5B000B;

$color-complement-0: #2A900F;	// Main Complement color */
$color-complement-1: #60C146;
$color-complement-2: #3FB221;
$color-complement-3: #187300;
$color-complement-4: #114F00;